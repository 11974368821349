import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { useState } from 'react'
import line from '../img/social/line.svg'
import Img from "gatsby-image"

const mapOptions = {
  mapTypeControl:        true,
  mapTypeControlOptions: {
    mapTypeIds: [
      'satellite',
      'roadmap',
    ]
  },
  mapTypeId:             'satellite',
  streetViewControl:     true,
  styles: [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "off"
          }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "simplified"
          },
          {
            "hue": "#0066ff"
          },
          {
            "saturation": 74
          },
          {
            "lightness": 100
          }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "simplified"
          }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "simplified"
          }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "off"
          },
          {
            "weight": 0.6
          },
          {
            "saturation": -85
          },
          {
            "lightness": 61
          }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
          {
            "visibility": "on"
          }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "off"
          }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "on"
          }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "simplified"
          }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
            "visibility": "simplified"
          },
          {
            "color": "#5f94ff"
          },
          {
            "lightness": 26
          },
          {
            "gamma": 5.86
          }
      ]
    }
  ]
}

export const PropertyPageTemplate = ({
  agent,
  content,
  contentComponent,
  coverImage,
  description,
  cardImage,
  freeTransfer,
  helmet,
  location,
  price,
  size,
  tags,
  title,
  images,
}) => {

  const PostContent = contentComponent || Content

  const [modal, setModal] = useState(false)

  const { lat, lng, province } = location

  function toggleModal() {
    if (!modal) {
      setModal(true)
    } else {
      setModal(false)
    }
  }

  return (
    <>
      <div
        className="full-width-image-container mt-0"
        style={{
          backgroundImage: `url(${
            !!coverImage.childImageSharp ? coverImage.childImageSharp.fluid.src : coverImage
          })`
        }}
      >
        <div className="full-width-image-container-content">
          <h1
            className="full-width-image-container-heading has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-desktop is-uppercase"
            style={{
              color: 'white',
              padding: '1rem',
            }}
          >
            {title}
          </h1>
        </div>
      </div>
      <section className="section">
        {helmet || ''}
        <div className="container content pb-5">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="columns">
                <div className="column is-8">
                  <div className="has-padding-left-10-mobile has-padding-right-10">
                    <div className="mb-6">
                      <p className="subtitle is-size-5 is-block">
                        <span
                          className="icon-text"
                          style={{
                            marginLeft: '-3px'
                          }}
                        >
                          <span className="icon">
                            <i className="icon-map-marker-alt"></i>
                          </span>
                          <span>{province}</span>
                        </span>
                      </p>
                      <p>
                        <span className="card-tag tag is-white">
                          <span className="icon-text">
                            <span className="icon">
                              <i className="icon-drafting-compass"></i>
                            </span>
                            <span>{size}</span>
                          </span>
                        </span>
                        {freeTransfer && (
                          <span className="tag is-white ml-2">
                            ฟรีโอน
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="description">
                      <HTMLContent
                        content={description}
                      />
                      { lat && lng && (
                        <>
                          <h5 className="mt-5">สถานที่ตั้ง</h5>
                          <hr className="mb-5" />
                          <LoadScript
                            googleMapsApiKey="AIzaSyChgDfCIyaY2_djS-Pas5la0cKM6NVFF1I"
                            >
                              <GoogleMap
                                mapContainerStyle={{
                                  width: '100%',
                                  height: '450px'
                                }}
                                center={{
                                  lat,
                                  lng,
                                }}
                                options={mapOptions}
                                zoom={17}
                              >
                                <Marker
                                  position={{
                                    lat,
                                    lng,
                                  }}
                                />
                              </GoogleMap>
                            </LoadScript>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="has-padding-left-10-mobile has-padding-right-10-mobile">
                    {price.selling ? (
                      <p className="is-size-5-desktop">
                        <span className="has-text-weight-bold">ซื้อ:</span> {price.selling}
                      </p>
                    ) : null }
                    {price.rental ? (
                      <p className="is-size-5-desktop">
                        <span className="has-text-weight-bold">เช่า:</span> {price.rental}
                      </p>
                    ) : null }

                    <hr />

                    <button className="button is-medium is-primary is-link has-text-weight-bold is-fullwidth mt-6" to="/property" onClick={toggleModal}>
                      ติดต่อตัวแทน
                    </button>

                    {modal ? (
                      <div className="modal is-active">
                        <div className="modal-background"></div>
                        <div className="modal-card">
                          <header className="modal-card-head">
                            <p className="modal-card-title has-text-weight-bold mb-0">Agent</p>
                            <button className="delete" aria-label="close" onClick={toggleModal}></button>
                          </header>
                          <section className="modal-card-body">
                            <div className="columns is-mobile">
                              <div className="column is-narrow">
                                <p className="is-size-4 has-text-weight-bold">{agent.name}</p>
                                <p className="mb-1">
                                  <span className="has-text-weight-bold is-inline-block has-width-50">โทร: </span>
                                  <a href={`tel:+66${agent.phone}`}>{agent.phone}</a>
                                </p>
                                <p className="mb-1">
                                  <span className="has-text-weight-bold is-inline-block has-width-50">อีเมล: </span>
                                  <a href={`mailto:${agent.email}`}>{agent.email}</a>
                                </p>
                                <p className="mb-5">
                                  <span className="has-text-weight-bold is-inline-block has-width-50">LINE: </span>
                                  <a href={`https://line.me/ti/p/${agent.line}`} target="_blank">{agent.line}</a>
                                </p>
                                <p className="mb-3">
                                  <a className="button is-line has-text-weight-bold pr-5" href={`https://line.me/ti/p/${agent.line}`} target="_blank">
                                    <img className="social-image" src={line} alt="Line - เพิ่มเพื่อน" style={{ width: '1.5em', height: '1.5em'}} /> เพิ่มเพื่อน
                                  </a>
                                </p>
                              </div>
                              <div className="column is-6 is-flex is-justify-content-flex-end">

                                {/*
                                  <figure className="image">
                                    <img
                                      className="is-rounded has-height-200 has-width-200"
                                      src={!!agent.photo.childImageSharp ? agent.photo.childImageSharp.fluid.src : agent.photo}
                                    />
                                  </figure>
                                */}

                              </div>
                            </div>
                          </section>
                          <footer className="modal-card-foot has-text-centered" />
                        </div>
                      </div>
                    ) : null }
                  </div>
                </div>
              </div>
              <div className="has-padding-left-10-mobile has-padding-right-10-mobile">

                {images && <hr className="my-6" />}

                {images && images.map((image, index) => (
                  <figure className="image" key={index}>
                      <Img fluid={image.childImageSharp.fluid} />
                  </figure>
                ))}

                <hr />

                <button className="button is-medium is-primary is-link has-text-weight-bold is-fullwidth mt-6" to="/property" onClick={toggleModal}>
                  ติดต่อตัวแทน
                </button>

                  {/*
                    {tags && tags.length ? (
                      <div style={{ marginTop: `4rem` }}>
                        <h4>Tags</h4>
                        <ul className="taglist">
                          {tags.map((tag) => (
                            <li key={tag + `tag`}>
                              <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                 */}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

PropertyPageTemplate.propTypes = {
  agent: PropTypes.object,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  coverImage: PropTypes.object,
  description: PropTypes.string,
  cardImage: PropTypes.object,
  freeTransfer: PropTypes.bool,
  helmet: PropTypes.object,
  image: PropTypes.object,
  location: PropTypes.object,
  price: PropTypes.object,
  size: PropTypes.string,
  title: PropTypes.string,
}

const Property = ({ data }) => {

  const { markdownRemark: post } = data

  return (
    <Layout>
      <PropertyPageTemplate
        agent={post.frontmatter.agent}
        content={post.html}
        contentComponent={HTMLContent}
        coverImage={post.frontmatter.coverImage}
        description={post.frontmatter.description}
        cardImage={post.frontmatter.cardImage}
        freeTransfer={post.frontmatter.freeTransfer}
        helmet={
          <Helmet titleTemplate="%s | Property">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        images={post.frontmatter.images}
        location={post.frontmatter.location}
        price={post.frontmatter.price}
        size={post.frontmatter.size}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        images={post.frontmatter.images}
      />
    </Layout>
  )
}

Property.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Property

export const pageQuery = graphql`
  query PropertyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        agent {
          name
          email
          line
          phone
          photo {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        coverImage {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        description
        cardImage {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        location {
          lat
          lng
          province
        }
        price {
          rental
          selling
        }
        freeTransfer
        size
        tags
        title
        images {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
